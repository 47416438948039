import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import PaymentsFeature1 from './PaymentsFeature1';
import PaymentsFeature2 from './PaymentsFeature2';
import PaymentsFeature3 from './PaymentsFeature3';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Payments = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Онлайн платежі' />
            <PaymentsFeature1 />
            <PaymentsFeature2 />
            <PaymentsFeature3 />
            <Apps />
            <Footer />
        </>
    );
};

export default Payments;
