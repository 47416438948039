import React from 'react';
import { Fade } from 'react-reveal';
import feature2 from '../../../../assets/images/features/Payments-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">


                    <div className="row align-items-center">

                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Онлайн платежі</span>
                                        <h2 className="heading-title sc-mb-25">Зручні платежі з Телеграмом - ваша зона комфорту!</h2>
                                        <div className="description sc-mb-25">
                                           Зручність та ефективність у використанні, оскільки платіжний процес інтегрований безпосередньо в платформу чату, не потребуючи переходу до зовнішніх сервісів або додаткових додатків.
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Телеграм має мільйони активних користувачів по всьому світу, що дозволяє організаторам отримати доступ до широкого аудиторії для продажу своїх послуг.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Зручність та швидкість оплати в Телеграмі може збільшити конверсію, оскільки спрощений процес оплати може збільшити імпульсивні покупки.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Користувачі можуть легко відстежувати свої транзакції та отримувати повідомлення про статус оплати безпосередньо у чаті Телеграму.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Легка інтеграція з іншими сервісами та платіжними шлюзами, та надання різноманітних опцій оплати для користувачів.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature2} alt="Feature" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
