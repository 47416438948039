import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import DesignFeature1 from './DesignFeature1';
import DesignFeature2 from './DesignFeature2';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Design = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Персоналізований дизайн' />
            <DesignFeature1 />
            <DesignFeature2 />
            <Apps />
            <Footer />
        </>
    );
};

export default Design;
