import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Data = ({participant}) => {

  const getSettings = () => participant?.tournament?.definition_vote_settings?.settings || {}
  const getSettingsScores = () => getSettings().scores || []
  const getSettingsPenalty = () => getSettings().penalty || []

  const getScoreTitle = key => getSettingsScores().find(s => s.key === key)?.title
  const getPenaltyTitle = key => getSettingsPenalty().find(s => s.key === key)?.title

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-20 sc-pb-180 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <h5>{participant?.group_name_short}</h5>
                            <h5>{participant?.title}</h5>
                            <h5>{participant?.coach}</h5>
                            <h5>{participant?.school}</h5>
                            <h5>Загалом: {participant?.total} балів</h5>
                        </div>
                        <table style={{width: '100%'}}>
                            <tbody>
                                {(participant?.votes || []).map((vote, index) => (
                                    <React.Fragment key={vote.id}>
                                        <tr>
                                            <td colSpan={2}>
                                                <b>
                                                    {vote.profile ? `Суддя ${vote.profile}` : `Суддя №${index + 1}`}
                                                </b>
                                            </td>
                                        </tr>
                                        {!!vote.comments && (
                                            <tr>
                                                <td colSpan={2}><b>Коментар судді:</b> {vote.comments}</td>
                                            </tr>
                                        )}
                                        {!!vote.scores && !Array.isArray(vote.scores) && Object.keys(vote.scores).map((scoreKey, p) => (
                                            <React.Fragment key={p}>
                                                <tr>
                                                    <td colSpan={2} align="center"><b>{getScoreTitle(scoreKey)}</b></td>
                                                </tr>
                                                {vote.scores[scoreKey].filter(f => f.score).map((scoreRow, scoreIndex) => (
                                                    <tr key={scoreIndex} style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{scoreRow.label}</td>
                                                        <td style={{color: 'green'}}>{scoreRow.score.toFixed(2)} з {scoreRow.max}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        {!!vote.penalty && !Array.isArray(vote.penalty) && Object.keys(vote.penalty).map((penaltyKey, p) => (
                                            <React.Fragment key={p}>
                                                <tr>
                                                    <td colSpan={2} align="center"><b>{getPenaltyTitle(penaltyKey)}</b></td>
                                                </tr>
                                                {vote.penalty[penaltyKey].filter(f => f.penalty).map((penaltyRow, penaltyIndex) => (
                                                    <tr key={penaltyIndex} style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{penaltyRow.label}</td>
                                                        <td style={{color: 'red'}}>{penaltyRow.penalty.toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        <tr>
                                            <td colSpan={2}>&nbsp;</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="row">
                            <a href="https://arena.promo/insurance" target="_blank" style={{textDecoration: 'none', color: '#0979e5', margin: 'auto', textAlign: 'center', marginBotton: '20px'}}>
                                🚑 🏥 Придбати страховий поліс 🏥 🚑
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Data;
