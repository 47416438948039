import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Form from './Form';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';
import axios from 'axios';

const Edit = () => {
    const props = useParams();

    const [proposal, setProposal] = useState(null)

    const loadParticipant = async () => {
        const data = await axios.get(`https://api.arena.promo/api/participant/${props.scope}/proposal/${props.id}/${props.timestamp}`)
        setProposal(data?.data)
    }

    useEffect(() => {
        loadParticipant()
    }, [props])

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={`Редагування заявки ${proposal?.name }`} />

            {!!proposal && (
                <Form proposal={proposal} />
            )}
            <Footer />
        </>
    );
};

export default Edit;
