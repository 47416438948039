import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <li className="menu-item-has-children">
                <Link className={location.pathname.includes('/features') ? 'active' : ''} to="#">Можливості</Link>
                <ul className="sub-menu-list" style={{width: '300px'}}>
                    <li><Link className={location.pathname === '/features/manager' ? 'active' : ''} to="/features/manager">Система для організатора</Link></li>
                    <li><Link className={location.pathname === '/features/schedule' ? 'active' : ''} to="/features/schedule">Онлайн табло</Link></li>
                    <li><Link className={location.pathname === '/features/judge' ? 'active' : ''} to="/features/judge">Суддівська система</Link></li>
                    <li><Link className={location.pathname === '/features/payments' ? 'active' : ''} to="/features/payments">Онлайн платежі</Link></li>
                    <li><Link className={location.pathname === '/features/tickets' ? 'active' : ''} to="/features/tickets">Вхідні квитки</Link></li>
                    <li><Link className={location.pathname === '/features/telegram' ? 'active' : ''} to="/features/telegram">Telegram Bot</Link></li>
                    <li><Link className={location.pathname === '/features/telegram-business' ? 'active' : ''} to="/features/telegram-business">Telegram Business Bot</Link></li>
                    <li><Link className={location.pathname === '/features/design' ? 'active' : ''} to="/features/design">Персональні налаштування</Link></li>
                    <li><Link className={location.pathname === '/features/reports' ? 'active' : ''} to="/features/reports">Звітність і документи</Link></li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link className={location.pathname.includes('/sports') ? 'active' : ''} to="#">Спорт</Link>
                <ul className="sub-menu-list" style={{width: '300px'}}>
                    <li><Link className={location.pathname === '/sports/air-gymnastics' ? 'active' : ''} to="/sports/air-gymnastics">Повітряна гімнастика</Link></li>
                    <li><Link className={location.pathname === '/sports/sport-dances' ? 'active' : ''} to="/sports/sport-dances">Бальні та спортивні танці</Link></li>
                    <li><Link className={location.pathname === '/sports/pole-dance' ? 'active' : ''} to="/sports/pole-dance">Pole dance</Link></li>
                    <li><Link className={location.pathname === '/sports/modern-dances' ? 'active' : ''} to="/sports/modern-dances">Сучасні танці</Link></li>
                </ul>
            </li>

            <li>
                <Link className={location.pathname.includes('/events')  ? 'active' : ''} to="/events">Події</Link>
            </li>
            <li>
                <Link className={location.pathname.includes('/gallery') ? 'active' : ''} to="/gallery">Фото</Link>
            </li>
            <li>
                <Link className={parentMenu === 'price' ? 'active' : ''} to="/price">Ціни</Link>
            </li>
            <li className="menu-item-has-children">
                <Link className={location.pathname.includes('/contact') ? 'active' : ''} to="#">Контакти</Link>
                <ul className="sub-menu-list" style={{width: '300px'}}>
                    <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/contact#">Контакти</Link></li>
                    <li><Link className={location.pathname === '/about' ? 'active' : ''} to="/about#">Про нас</Link></li>
                </ul>
            </li>
        </>
    );
}

export default MenuItems;
