import React from 'react';
import { Fade } from 'react-reveal';
import feature2 from '../../../../assets/images/features/Schedule-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">


                    <div className="row align-items-center">

                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Олімпійський формат</span>
                                        <h2 className="heading-title sc-mb-25">Все, що потрібно на долоні!</h2>
                                        <div className="description sc-mb-25">
                                          Онлайн табло забезпечує швидкий та зручний доступ до інформації у реальному часі.
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Миттеве оновлення</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Результати по кожному учаснику</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Як на олімпіаді!</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Розгорнута деталізація</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature2} alt="Feature" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
