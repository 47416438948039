import React from 'react';
import { Fade } from 'react-reveal';
import feature3 from '../../../../assets/images/features/Telegram-03.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature3} alt="Telegram Bot" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Telegram Bot</span>
                                        <h2 className="heading-title sc-mb-25">Ваш особистий помічник у світі Telegram</h2>
                                        <div className="description sc-mb-25">
                                          Зробіть свій турнір зручнішим з нашим телеграм-ботом: швидко, зручно, ефективно!
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Завжди актуальний перелік подій</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Привітання конкурсантам після оцінювання</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Нагадування конкурсантам про час виступу</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Можливість додання опитувань</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
