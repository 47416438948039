import React from 'react';
import { Fade } from 'react-reveal';
import feature2 from '../../../../assets/images/features/Reports-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">


                    <div className="row align-items-center">

                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Документація</span>
                                        <h2 className="heading-title sc-mb-25">Ваша документація: шлях до успіху!</h2>
                                        <div className="description sc-mb-25">
                                        Наша система документації - це повний комплекс інструментів і процесів, створений для того, щоб забезпечити ефективне управління та збереження інформації. Вона спрощує процес створення, зберігання, пошуку та оновлення документів, забезпечуючи легкий доступ до необхідної інформації в будь-який час і з будь-якого пристрою.
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Усі документи зберігаються в одному центральному місці, що спрощує їхнє керування та забезпечує єдиний джерело правди для всіх користувачів.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Документи організовані у логічні категорії та папки, що полегшує їхнє знаходження та використання.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Документи можуть бути доступні для перегляду та редагування з будь-якого місця, використовуючи будь-який пристрій з підключенням до Інтернету.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Система забезпечує високий рівень захисту даних, використовуючи різні методи автентифікації та шифрування для захисту конфіденційної інформації.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature2} alt="Feature" style={{borderRadius: '15px', width: '400px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
