import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Form from './Form';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';
import axios from 'axios';

const Document = () => {
    const props = useParams();

    const [participant, setParticipant] = useState(null)

    const loadParticipant = async () => {
        const data = await axios.get(`https://api.arena.promo/api/participant/${props.scope}/${props.type}/${props.id}/${props.timestamp}`)
          setParticipant(data?.data)
    }

    const urlParams = new URLSearchParams(window.location.search);
    const name =  decodeURI(urlParams.get('name'));

    useEffect(() => {
        loadParticipant()
    }, [props])

    const title = participant?.name ?? participant?.participant?.name

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={`Завантаження "${name}" до ${title}`} />
            <Form participant={participant} />
            <Footer />
        </>
    );
};

export default Document;
