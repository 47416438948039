import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Data = ({participant}) => {

  const getSettings = () => participant?.tournament?.definition_vote_settings?.settings || {}
  const getSettingsScores = () => getSettings().scores || []
  const getSettingsPenalty = () => getSettings().penalty || []

  const getScoreTitle = key => getSettingsScores().find(s => s.key === key)?.title
  const getPenaltyTitle = key => getSettingsPenalty().find(s => s.key === key)?.title

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-20 sc-pb-180 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <h5>{participant?.group_name_short}</h5>
                            <h5>{participant?.title}</h5>
                            <h5>{participant?.coach}</h5>
                            <h5>{participant?.school}</h5>
                            <br />
                            <br />
                            <h5>Техніка: {parseFloat(participant?.total_technique || 0).toFixed(2)}</h5>
                            <h5>Хореографія: {parseFloat(participant?.total_choreography || 0).toFixed(2)}</h5>
                            <h5>Артистизм: {parseFloat(participant?.total_artistry || 0).toFixed(2)}</h5>
                            <h5>Штрафи: {parseFloat(participant?.total_penalty || 0).toFixed(2)}</h5>
                            <h5>Загалом: {parseFloat(participant?.total || 0).toFixed(2)}</h5>
                            <h5>Місце: {participant?.rank}</h5>
                        </div>
                        <table style={{width: '100%'}}>
                            <tbody>
                                {(participant?.votes || []).map((vote, index) => (
                                    <React.Fragment key={vote.id}>
                                        <tr>
                                            <td colSpan={2}>
                                                <b>
                                                    {vote.profile ? `Суддя ${vote.profile}` : `Суддя №${index + 1}`}
                                                </b>
                                            </td>
                                        </tr>
                                        {!!vote.comments && (
                                            <tr>
                                                <td colSpan={2}><b>Коментар судді:</b> {vote.comments}</td>
                                            </tr>
                                        )}

                                        {!!vote.technique?.length && (
                                            <>
                                            <tr>
                                                <td colSpan={2} align="center"><b>Техніка</b></td>
                                            </tr>
                                            {vote.technique.map((tech, p) => (
                                                <React.Fragment key={p}>
                                                    <tr style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{tech.label}</td>
                                                        <td style={{color: 'green'}}>{tech.technique.toFixed(2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </>
                                        )}
                                        {!!vote.artistry?.length && (
                                            <>
                                            <tr>
                                                <td colSpan={2} align="center"><b>Артистизм</b></td>
                                            </tr>
                                            {vote.artistry.map((tech, p) => (
                                                <React.Fragment key={p}>
                                                    <tr style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{tech.label}</td>
                                                        <td style={{color: 'green'}}>{tech.artistry.toFixed(2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </>
                                        )}
                                        {!!vote.choreography?.length && (
                                            <>
                                            <tr>
                                                <td colSpan={2} align="center"><b>Хореографія</b></td>
                                            </tr>
                                            {vote.choreography.map((tech, p) => (
                                                <React.Fragment key={p}>
                                                    <tr style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{tech.label}</td>
                                                        <td style={{color: 'green'}}>{tech.choreography.toFixed(2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </>
                                        )}

                                        {!!vote.penalty?.length && (
                                            <>
                                            <tr>
                                                <td colSpan={2} align="center"><b>Штрафи</b></td>
                                            </tr>
                                            {vote.penalty.filter(p => p.penalty).map((tech, p) => (
                                                <React.Fragment key={p}>
                                                    <tr style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{tech.label}</td>
                                                        <td style={{color: 'red'}}>{tech.penalty.toFixed(2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </>
                                        )}
                                        <tr>
                                            <td colSpan={2}>&nbsp;</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="row">
                            <a href="https://arena.promo/insurance" target="_blank" style={{textDecoration: 'none', color: '#0979e5', margin: 'auto', textAlign: 'center', marginBotton: '20px'}}>
                                🚑 🏥 Придбати страховий поліс 🏥 🚑
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Data;
