import React from 'react';
import { Fade } from 'react-reveal';
import feature2 from '../../../../assets/images/features/Tickets-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">


                    <div className="row align-items-center">

                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Вхідні квитки</span>
                                        <h2 className="heading-title sc-mb-25">Швидкий доступ до вражень!</h2>
                                        <div className="description sc-mb-25">
                                          Переваги вхідних квитків по QR-кодам неперевершені: миттєвний доступ, безпека даних та надійний контроль. Просто скануйте, увійдіть і насолоджуйтеся!
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Використання QR-кодів дозволяє уникнути непотрібних очікувань і черг, забезпечуючи плавний та безперервний вхід на подію.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>: QR-коди можуть бути легко розповсюджені та використані в будь-якому місці світу, що робить їх універсальним засобом вхідного контролю на події.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Використання електронних квитків та QR-кодів може значно зменшити витрати на друкування та доставку паперових квитків.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>QR-коди можуть бути використані для взаємодії з учасниками події, надаючи доступ до додаткових матеріалів, акцій або інформації, що підвищує взаємодію та залучення гостей.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Використання QR-кодів у подійному менеджменті дозволяє значно зменшити час, необхідний для організації та контролю входу на заходи, тим самим звільняючи ресурси для інших аспектів організації подій.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" width="300" src={feature2} alt="Вхідні квитки" style={{borderRadius: '15px', marginLeft: '60px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
