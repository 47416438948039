import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import ReportsFeature1 from './ReportsFeature1';
import ReportsFeature2 from './ReportsFeature2';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Reports = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Звітність та документи' />
            <ReportsFeature1 />
            <ReportsFeature2 />
            <Apps />
            <Footer />
        </>
    );
};

export default Reports;
