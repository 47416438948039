import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const PricePlan = () => {
    return (
        <>
            <section className="sc-price-area gray-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25">Повітряна гімнастика</h2>
                        <span className="sub_title">Ціни та тарифні плани:</span>
                    </div>
                    <div className="pricing-monthly">
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Базовий</h4>
                                            <div className="price-active">₴2000</div>
                                        </div>
                                        <div className="perfect-title">Можливості</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Система для менеджерів</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Суддівська система</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Онлайн табло</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Telegram bot</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Premium підтримка 24/7</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Онлайн табло з оцінками олімпійського формату</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Демо-версія змагань разом з розробниками</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact" ><span>Спробувати</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30" >
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Стандарт</h4>
                                            <div className="price-active">₴5000.00</div>
                                        </div>
                                        <div className="perfect-title">Можливості</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Система для менеджерів</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Суддівська система</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Онлайн табло</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Telegram bot</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Premium підтримка 24/7</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Онлайн табло з оцінками олімпійського формату</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Демо-версія змагань разом з розробниками</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                          <Link className="price-btn" to="/contact" ><span>Спробувати</span></Link>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Преміум</h4>
                                            <div className="price-active">₴7000.00</div>
                                        </div>
                                        <div className="perfect-title">Можливості</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Система для менеджерів</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Суддівська система</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Онлайн табло</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Telegram bot</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Premium підтримка 24/7</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Онлайн табло з оцінками олімпійського формату</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Демо-версія змагань разом з розробниками</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                          <Link className="price-btn" to="/contact" ><span>Спробувати</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricePlan;
