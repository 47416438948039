import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { HashLink as Link } from 'react-router-hash-link';
import blogImg1 from '../../assets/images/blog/blog-img1.jpg'
import blogImg2 from '../../assets/images/blog/blog-img2.jpg'
import blogImg3 from '../../assets/images/blog/blog-img3.jpg'

import axios from 'axios';
import { Routes, Route, useParams } from 'react-router-dom';


const LatestBlog = () => {
    const [ events, setEvents ] = useState([])

    useEffect(() => {
        axios.get('https://api-festival-play.arena.promo/api/tournaments').then(payload => {
            setEvents(
                payload.data.map(event => ({
                    ...event,
                    date: (new Date(Date.parse(event.date))),
                    tournament: event.air_gymnastics || event.sport_dances || event.festival,
                    tournament_key: event.air_gymnastics ? 'air-gymnastics' : (event.sport_dances ? 'sport-dances' : (event.festival ? 'festival' : ''))
                })).filter(t => !t.tournament?.finished)
            )
        })
    }, [])

    return (
        <>
            <section className="sc-blog-area sc-pt-125 sc-md-pt-65 sc-sm-mb-30">
                <div className="container">
                    <div className="sc-heading-area sc-mb-55 sc-md-mb-45 text-center">
                        <h2 className="title">Події</h2>
                    </div>
                    <div className="blog-item-area slider-item-area sc-slick-slide sc-slick-slides">
                        <div className="latest-blog-carousel-wrapper">
                            <Carousel className="latest-blog-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={false}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>
                                {events.map(event => (
                                <div className="blog-item text-center">
                                    <div className="blog-image">
                                        <Link to={`/events/${event.id}`}>
                                          <img src={(event.tournament.media || [])[0]?.s3}
                                              height="250" alt={event?.name} style={{transform: 'none'}} />
                                        </Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-list-menu">
                                            <ul className="blog-list">
                                                <li>
                                                    <Link to={`/events/${event.id}`}>
                                                        <i className="ri-calendar-line"></i> {event.date.getDate()} {event.date.toLocaleString('default', { month: 'short' })} {event.date.getFullYear()}
                                                      </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <h3 className="blog-title sc-mt-20">
                                            <Link to={`/events/${event.id}`}>{event?.name}</Link>
                                        </h3>
                                    </div>
                                </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LatestBlog;
