import React from 'react';
import { Fade } from 'react-reveal';
import feature2 from '../../../../assets/images/features/Design-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">


                    <div className="row align-items-center">

                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Димпломи, нагороди</span>
                                        <h2 className="heading-title sc-mb-25">Автоматичне створення дипломів та нагород</h2>
                                        <div className="description sc-mb-25">
                                          На допомогу організаторам подій та заходів приходить автоматичне створення дипломів та нагород! Наша система дозволяє вам швидко та ефективно генерувати персоналізовані дипломи та нагороди для учасників вашої події. Зручний інтерфейс дозволяє налаштувати дизайн та вміст документів, додати необхідні дані та логотипи, та автоматично створити готові документи для друку або електронної доставки. Віддайте пріоритет вашій творчості та організації заходу, а наша система візьме на себе всю рутинну роботу з підготовки документів!
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Автоматизація процесу створення дипломів та нагород зменшує витрати часу, зусиль і ресурсів, які зазвичай витрачаються на ручне створення кожного документу.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Система автоматичного створення дозволяє миттєво генерувати велику кількість документів за короткий період часу, що особливо важливо при організації великих подій.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>За допомогою автоматизованих систем можна створювати персоналізовані дипломи та нагороди для кожного учасника, одночасно забезпечуючи їхню консистентність та стандартність.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Організатори подій можуть легко налаштувати шаблони дипломів та нагород у відповідності з власними потребами та бажаннями, а також автоматично генерувати документи в один клік.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature2} alt="Feature" style={{width: '450px', borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
