import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const ContactInfo = () => {
    const [ price, setPrice ] = useState('252')
    const [ date, setDate ] = useState('')
    const [ dob, setDob ] = useState('')
    const [ dob_child, setDobChild ] = useState('')
    const [ firstname, setFirstname ] = useState('')
    const [ lastname, setLastname ] = useState('')
    const [ surname, setSurname ] = useState('')
    const [ phone, setPhone ] = useState('')

    const [ name_child, setNameChild ] = useState('')
    const [ city, setCity ] = useState('')
    const [ street, setStreet ] = useState('')
    const [ number, setNumber ] = useState('')
    const [ flat, setFlat ] = useState('')
    const [ personal_number, setPersonalNumber ] = useState('')
    const [ passport, setPassport ] = useState('')


    const handleMessage = async (event) => {
        event.preventDefault()

        toast("Заявку на страхувальний поліс створено.", {type: 'success', autoClose: false})

        const data = await axios.post('https://api.arena.promo/api/insurance/proposals', {
            price: price,
            date: date,
            firstname: firstname,
            name_child: name_child,
            dob_child: dob_child,
            lastname: lastname,
            surname: surname,
            phone: phone,
            dob: dob,
            city: city,
            street: street,
            number: number,
            flat: flat,
            personal_number: personal_number,
            passport: passport,
        })

        if(data?.data?.id){
            event.target.reset()
        }
    }

    return (
        <>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">Придбати страхувальний поліс</span>
                                    <h2 className="heading-title sc-mb-45">Страхова компанія "СГ Оберіг"</h2>
                                </div>
                                <p>Дана послуга не повʼязана з жодним з організаторів змагань і надається партнерською компанією.</p>
                                <p>Будь-ласка, залиште ваші контактні дані. Страховий агент звʼяжеться з вами у найближчий час.</p>
                                <p>Оберіть суму страхового платежу:</p>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <label>
                                            <input type="radio" value="216" name="price" onChange={e => setPrice(e.target.value)} />
                                            &nbsp;216грн - страхова сума 30,000грн (7 днів)
                                        </label>
                                        <label>
                                            <input type="radio" value="252" name="price" defaultChecked onChange={e => setPrice(e.target.value)} />
                                            &nbsp;252грн - страхова сума 35,000грн (7 днів)
                                        </label>
                                        <label>
                                            <input type="radio" value="288" name="price" onChange={e => setPrice(e.target.value)} />
                                            &nbsp;288грн - страхова сума 40,000грн (7 днів)
                                        </label>
                                        <label>
                                            <input type="radio" value="324" name="price" onChange={e => setPrice(e.target.value)} />
                                            &nbsp;324грн - страхова сума 45,000грн (7 днів)
                                        </label>
                                        <label>
                                            <input type="radio" value="360" name="price" onChange={e => setPrice(e.target.value)} />
                                            &nbsp;360грн - страхова сума 50,000грн (7 днів)
                                        </label>
                                    </div>
                                    <div className="contact-service-box">

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-5">
                                    <form onSubmit={handleMessage}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата турніру/фестивалю*</label>
                                                <input className="from-control" type="date" id="date" name="date" placeholder="Оберіть дату" onChange={e => setDate(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Імʼя страхувальника*</label>
                                                <input className="from-control" type="text" id="firstname" name="firstname" placeholder="Напишіть імʼя страхувальника" onChange={e => setFirstname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Прізвище страхувальника*</label>
                                                <input className="from-control" type="text" id="lastname" name="lastname" placeholder="Напишіть прізвище страхувальника" onChange={e => setLastname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">По-батькові страхувальника*</label>
                                                <input className="from-control" type="text" id="surname" name="surname" placeholder="Напишіть по-батькові страхувальника" onChange={e => setSurname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">ПІБ застрахованої особи (якщо учасник неповнолітній)</label>
                                                <input className="from-control" type="text" id="name_child" name="name_child" placeholder="Напишіть ПІБ дитини" onChange={e => setNameChild(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Телефон*</label>
                                                <input className="from-control" type="text" id="phone" name="phone" placeholder="Напишіть ваш номер телефону" onChange={e => setPhone(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата народження*</label>
                                                <input className="from-control" type="date" placeholder="Оберіть дату народження" onChange={e => setDob(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата народження застрахованої особи (якщо учасник неповнолітній)</label>
                                                <input className="from-control" type="date" placeholder="Оберіть дату народження дитини" onChange={e => setDobChild(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Місто*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваше місто" onChange={e => setCity(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Вулиця*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть вашу вулицю" onChange={e => setStreet(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер дому*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш номер дому" onChange={e => setNumber(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер квартири</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш номер квартири" onChange={e => setFlat(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">ІНН*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш ІНН" onChange={e => setPersonalNumber(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер паспорту*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш номер паспорту" onChange={e => setPassport(e.target.value)} required autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Відправити" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;
