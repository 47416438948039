import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import blogImg1 from '../../../assets/images/blog/blog.png'
import blogImg2 from '../../../assets/images/blog/blog_2.png'
import blogImg3 from '../../../assets/images/blog/blog_3.png';
import axios from 'axios';

const BlogSidebar = () => {

    const [ events, setEvents ] = useState([])

    useEffect(() => {
        axios.get('https://api-festival-play.arena.promo/api/tournaments').then(payload => {
            setEvents(
                payload.data.map(event => ({
                    ...event,
                    date: (new Date(Date.parse(event.date))),
                    tournament: event.air_gymnastics || event.sport_dances || event.festival,
                    tournament_key: event.air_gymnastics ? 'air-gymnastics' : (event.sport_dances ? 'sport-dances' : (event.festival ? 'festival' : ''))
                })).filter(t => !t.tournament?.finished)
            )
        })
    }, [])

    return (
        <>
            <div className="categories-area">
                {/*
                <div className="cate_form sc-mb-35">
                    <h3 className="title sc-mb-23">Search</h3>
                    <form className="catergories-form" onSubmit={handleSidebarSearch}>
                        <input type="text" id="Search" name="search" placeholder="Search... " />
                        <button type="submit" value="submit"><i className="ri-search-line"></i></button>
                    </form>
                </div>*/}
                    <div className="post-item sc-mb-35">
                        <h3 className="title sc-mb-20">Останні події</h3>
                        {events.map(event => (
                            <div className="recent-content d-flex align-items-center sc-mb-13">
                                <Link to={`/events/${event.id}`}>
                                    <div className="recent-image">
                                        <img src={(event.tournament.media || [])[0]?.s3} alt="Recent" width="100px" style={{minWidth: '100px'}} />
                                    </div>
                                </Link>
                                <div className="recent-text sc-pl-20">
                                    <h5>
                                        <Link to={`/events/${event.id}`}>
                                            {event.tournament?.name}
                                        </Link>
                                    </h5>
                                    <div className="calender-item">
                                        <i className="ri-calendar-fill"></i>
                                        <span>{event.date.getDate()} {event.date.toLocaleString('default', { month: 'short' })}, {event.date.getFullYear()}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                {/*
                <div className="categories-item sc-mb-25">
                    <h3 className="title sc-mb-15">Category</h3>
                    <ul className="list">
                        <li><Link to="#">UX Design</Link></li>
                        <li><Link to="#">UI Design</Link></li>
                        <li><Link to="#">Graphic Design</Link></li>
                        <li><Link to="#">App Design</Link></li>
                    </ul>
                </div>
                <div className="categories-tag">
                    <h3 className="title sc-mb-15">Tag</h3>
                    <ul className="list">
                        <li><Link to="#">SaaS</Link></li>
                        <li><Link to="#">UI Design</Link></li>
                        <li><Link to="#">Creative</Link></li>
                        <li><Link to="#">Landing</Link></li>
                        <li><Link to="#">Minimal</Link></li>
                    </ul>
                </div>
                */}
            </div>
        </>
    );
};

export default BlogSidebar;
