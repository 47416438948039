import React from 'react';
import { Fade } from 'react-reveal';
import feature1 from '../../../../assets/images/features/Telegram-01.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature1} alt="Telegram Bot" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Telegram Bot</span>
                                        <h2 className="heading-title sc-mb-25">Один бот, безліч можливостей!</h2>
                                        <div className="description sc-mb-25">
                                          Це програмний скрипт, що функціонує в месенджері Telegram і призначений для автоматизації різних завдань.
                                        </div>
                                        <div className="description sc-mb-25">
                                          <a href="https://t.me/ArenaPromoBot" target="_blank">https://t.me/ArenaPromoBot</a>
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Ваші дані на всіх пристроях</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Можливість реєстрації за 1 хвилину</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Персоналізація</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Миттеве оновлення</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Можливість додання Вашої реклами</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
