import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const ContactInfo = () => {
    const [ name, setName ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ message, setMessage ] = useState('')

    const handleMessage = (event) => {
        toast("Message Sent Successfully")

        axios.post('https://api.arena.promo/api/support/contact', {
            name: name,
            phone: phone,
            message: message
        })

        event.preventDefault()
        //event.target.reset()
    }

    return (
        <>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">На звʼязку</span>
                                    <h2 className="heading-title sc-mb-45">Наша команда завжди готова вам допомгти 24/7</h2>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-map-pin-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul>
                                            <li>Дніпро<span>Україна</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-mail-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href="mailto:arena.promo.ua@gmail.com">arena.promo.ua@gmail.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <i className="ri-phone-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href="tel:+380938406236">+380 93 609 67 84</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-10">
                                    <form onSubmit={handleMessage}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <label className="sc-mb-10">Імʼя*</label>
                                                <input className="from-control" type="text" id="name" name="name" placeholder="Напишіть ваше імʼя" onChange={e => setName(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Телефон*</label>
                                                <input className="from-control" type="text" id="phone" name="phone" placeholder="Напишіть ваш номер телефону" onChange={e => setPhone(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label>Повідомлення*</label>
                                                <textarea id="message" name="message" placeholder="Текст повідомлення" autoComplete="off" onChange={e => setMessage(e.target.value)} required></textarea>
                                            </div>
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Відправити" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;
