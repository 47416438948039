import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/sports/PoleDance-01.png'

const PoleDanceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Pole Dance" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Pole dance</span>
                                        <h2 className="heading-title sc-mb-25"> Ваш шлях до гармонії тіла та душі!</h2>
                                        <div className="description sc-mb-25">
                                          Pole Dance може бути поєднаний з різними стилями танцю, включаючи елементи сучасного танцю, еротичного танцю, латиноамериканських танців та іншого. Pole Dance отримав популярність як форма фізичної активності, яка також сприяє покращенню координації, сили та гнучкості. Він також виступає як форма мистецтва, яка може використовуватися як самовираження та засіб вираження сексуальності.
                                        </div>
                                        <div className="description sc-mb-25">
                                            Його популярність зростає, оскільки він пропонує комбінацію тренування сили, гнучкості та кардіо. Багато людей займаються Pole Dance для того, щоб покращити своє тіло, підвищити впевненість у собі та виразити свою творчість через танець.
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Фізична активність: Виконання Pole Dance вимагає значної фізичної активності, що допомагає покращити стан здоров'я та фізичну форму. Це включає в себе підвищення витривалості, сили, гнучкості та координації.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Збільшення впевненості у собі: Виконання Pole Dance допомагає людям відчувати себе більш впевнено та відчувати свою силу і гнучкість, що може позитивно позначитися на їхньому самопочутті/</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Емоційне відання: Танцювання може бути емоційно віданням, яке допомагає визволити стрес та напругу, покращити настрій та забезпечити відчуття задоволення.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Творчий вираз: Pole Dance дає можливість виразити творчість та індивідуальність через рухи та танцювальні комбінації.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Здоровий спосіб життя: Практика Pole Dance сприяє утриманню здорового способу життя через фізичну активність та позитивний підхід до тіла та самопочуття.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PoleDanceFeature;
