import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import TelegramFeature1 from './TelegramFeature1';
import TelegramFeature2 from './TelegramFeature2';
import TelegramFeature3 from './TelegramFeature3';
import TelegramFeature4 from './TelegramFeature4';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Manager = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Telegram Bot' />
            <TelegramFeature1 />
            <TelegramFeature2 />
            <TelegramFeature3 />
            <TelegramFeature4 />
            <Apps />
            <Footer />
        </>
    );
};

export default Manager;
