import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import DetailsData from './DetailsData';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

import axios from 'axios';
import { Routes, Route, useParams } from 'react-router-dom';


const BlogDetails = () => {

    let { id } = useParams();

    const [ event, setEvent ] = useState(null)

    useEffect(() => {
        axios.get('https://api-festival-play.arena.promo/api/tournaments/' + id).then(payload => {
            setEvent({
                ...payload.data,
                date: (new Date(Date.parse(payload.data.date))),
                tournament: payload.data.air_gymnastics || payload.data.sport_dances || payload.data.festival,
                tournament_key: payload.data.air_gymnastics ? 'air-gymnastics' : (payload.data.sport_dances ? 'sport-dances' : (payload.data.festival ? 'festival' : ''))
            })
        })
    }, [id])

    return (
        <>
            <Header
                parentMenu='blog'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={event?.name} />
            {!!event && <DetailsData event={event} />}
            <Apps />
            <Footer />
        </>
    );
};

export default BlogDetails;
