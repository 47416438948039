import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import JudgeFeature1 from './JudgeFeature1';
import JudgeFeature2 from './JudgeFeature2';
import JudgeFeature3 from './JudgeFeature3';
import JudgeFeature4 from './JudgeFeature4';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Manager = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Суддівська система' />
            <JudgeFeature1 />
            <JudgeFeature2 />
            <JudgeFeature3 />
            <JudgeFeature4 />
            <Apps />
            <Footer />
        </>
    );
};

export default Manager;
