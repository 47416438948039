import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';
import { Routes, Route, useParams } from 'react-router-dom';

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const GalleryView = () => {
    const [index, setIndex] = useState(-1);
    const [nextIndex, setNextIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(0);
    const [nextImage, setNextImage] = useState(null);
    const [prevImage, setPrevImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [ images, setImages ] = useState([])
    const [ tournament, setTournament ] = useState(null)
    let { id } = useParams();

    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    useEffect(() => {
        axios.get(`https://api-festival-play.arena.promo/api/tournaments/${id}/gallery`).then(payload => {
            setImages(payload.data.map(image => ({
    src: image.thumbnails['400'],
    original: image.thumbnails['1200'],
    width: 400,
//    height: 174,
    caption: "",
            })))
        })
    }, [id])

    useEffect(() => {
        axios.get(`https://api-festival-play.arena.promo/api/tournaments/${id}`).then(payload => {
            setTournament(payload.data)
        })
    }, [id])

    useEffect(() => {
      if(index < 0){
          setCurrentImage(null)
          return;
      }
      setCurrentImage(images[index])

      setNextIndex((index + 1) % images.length)
      setNextImage(images[nextIndex] || currentImage)
      setPrevIndex((index + images.length - 1) % images.length)
      setPrevImage(images[prevIndex] || currentImage)

    }, [index])

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <>
        {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
        <Header
            parentMenu='events'
            headerClass='sc-header-section inner-header-style'
            signUpBtn='inner-btn'
        />
        <BreadCums pageTitle={`Фото галерея ${tournament?.festival?.name}`} />

            <div className="sc-blog-pages-area sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                <div className="container center">
                <Gallery
    images={images}
    onClick={handleClick}
    enableImageSelection={false}
    margin={2}
    rowHeight={200}
  />
                </div>
            </div>

            <Apps />
            <Footer />
        </>
    );
};

export default GalleryView;
