import React from 'react';
import { Fade } from 'react-reveal';
import feature1 from '../../../../assets/images/features/Tickets-01.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" width="300" src={feature1} alt="Вхідні квитки" style={{borderRadius: '15px', marginLeft: '60px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Миттєвний вхід до вашого світу!</span>
                                        <h2 className="heading-title sc-mb-25">Ваша подія, ваша система!</h2>
                                        <div className="description sc-mb-25">
                                          Забудьте про марні втрати часу в черзі! Наша система використовує QR-коди для швидкого та безпечного входу на ваші події. Просто отримайте квиток з унікальним QR-кодом, проскануйте його за допомогою вашого смартфона і насолоджуйтесь враженнями, які чекають на вас. Це швидко, ефективно та зручно!
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                        <li><i className="ri-checkbox-circle-fill"></i>Сканування QR-кодів дозволяє швидко та без зайвих зусиль перевіряти вхідні квитки на події.</li>
                                        <li><i className="ri-checkbox-circle-fill"></i>Унікальні QR-коди можуть бути легко перевірені та важко підроблені, що забезпечує надійний захист від підробок.</li>
                                        <li><i className="ri-checkbox-circle-fill"></i>Квитки можуть бути збережені в електронному форматі на смартфоні або планшеті, що уникне необхідності друкування та дозволить користувачам легко мати їх під рукою.</li>
                                        <li><i className="ri-checkbox-circle-fill"></i>QR-коди можуть містити додаткові дані або параметри, які дозволяють організаторам подій збирати аналітику про відвідувачів та їх участь у події.</li>
                                        <li><i className="ri-checkbox-circle-fill"></i>Системи сканування QR-кодів можуть бути легко інтегровані з іншими інформаційними системами для автоматичного внесення даних про учасників події та контролю входу.</li>
                                        <li><i className="ri-checkbox-circle-fill"></i>Використання QR-кодів дозволяє легко налаштовувати та масштабувати систему квиткування відповідно до потреб організаторів подій.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
