import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import View from './View';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';

const Tickets = () => {
    const props = useParams();

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Ваш квиток' />
            <View {...props} />
            <Footer />
        </>
    );
};

export default Tickets;
