import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import TicketsFeature1 from './TicketsFeature1';
import TicketsFeature2 from './TicketsFeature2';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Manager = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Вхідні квитки' />
            <TicketsFeature1 />
            <TicketsFeature2 />
            <Apps />
            <Footer />
        </>
    );
};

export default Manager;
