import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoDark from '../../assets/images/logo.png'

const OffCanvasMenu = (props) => {
    const [iconToggle1, setIconToggle1] = useState(true)
    const [iconToggle2, setIconToggle2] = useState(true)
    const [iconToggle3, setIconToggle3] = useState(true)

    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="ri-menu-line"></i></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <Link to="/#"><img src={logoDark} alt="DarkLogo" /></Link>
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown">
                                            <Link className={location.pathname === '/' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle1(!iconToggle1)}>
                                                Головна
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'} to="/about#">Про нас</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle2(!iconToggle2)}>
                                                Види спорту
                                                {iconToggle2 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <Link className={location.pathname === '/sports/air-gymnastics' ? 'dropdown-item active' : 'dropdown-item'} to="/sports/air-gymnastics">Повітряна гімнастика</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/sports/sport-dances' ? 'dropdown-item active' : 'dropdown-item'} to="/sports/sport-dances">Бальні та спортивні танці</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/sports/pole-dance' ? 'dropdown-item active' : 'dropdown-item'} to="/sports/pole-dance">Pole dance</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/sports/modern-dances' ? 'dropdown-item active' : 'dropdown-item'} to="/sports/modern-dances">Сучасні танці</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown ">
                                            <Link className={parentMenu === 'blog' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle3(!iconToggle3)}>
                                                Можливості
                                                {iconToggle3 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><Link className={location.pathname === '/features/manager' ? 'dropdown-item active' : 'dropdown-item'} to="/features/manager">Система для організатора</Link></li>
                                                <li><Link className={location.pathname === '/features/schedule' ? 'dropdown-item active' : 'dropdown-item'} to="/features/schedule">Онлайн табло</Link></li>
                                                <li><Link className={location.pathname === '/features/judge' ? 'dropdown-item active' : 'dropdown-item'} to="/features/judge">Суддівська система</Link></li>
                                                <li><Link className={location.pathname === '/features/telegram' ? 'dropdown-item active' : 'dropdown-item'} to="/features/telegram">Telegram Bot</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/events' ? 'nav-link active' : 'nav-link'} to="/events">Події</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/price' ? 'nav-link active' : 'nav-link'} to="/price">Ціни</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact">Контакти</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/manager"><i className="ri-lock-line"></i>Вхід </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <div className="offcanvas-icon-list">
                            <ul>
                                <li>
                                    <a href="https://t.me/ArenaPromoBot"><i className="ri-telegram-fill"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;
