import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import TelegramBusinessFeature1 from './TelegramBusinessFeature1';
import TelegramBusinessFeature2 from './TelegramBusinessFeature2';
import TelegramBusinessFeature3 from './TelegramBusinessFeature3';
import TelegramBusinessFeature4 from './TelegramBusinessFeature4';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const TelegramBusiness = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Telegram Business Bot' />
            <TelegramBusinessFeature1 />
            <TelegramBusinessFeature2 />
            <TelegramBusinessFeature3 />
            <TelegramBusinessFeature4 />
            <Apps />
            <Footer />
        </>
    );
};

export default TelegramBusiness;
