import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import testImage from '../../../assets/images/testimonials/test-img.png'
import groupImage from '../../../assets/images/testimonials/group_img.png'
import groupImage1 from '../../../assets/images/testimonials/group_img1.png'
import groupImage2 from '../../../assets/images/testimonials/group_img2.png'
import groupImage3 from '../../../assets/images/testimonials/group_img3.png'
import groupImage4 from '../../../assets/images/testimonials/group_img4.png'
import groupImage5 from '../../../assets/images/testimonials/group_img5.png'
import chernish from '../../../assets/images/testimonials/chernish.jpg'

const Testimonial = () => {

    const CustomRightArrow = ({ onClick }) => {
        return <button className='commonArrow arrowRight' onClick={() => onClick()}><i className="ri-arrow-right-line"></i></button>;
    };
    const CustomLeftArrow = ({ onClick }) => {
        return <button className='commonArrow arrowLeft' onClick={() => onClick()}><i className="ri-arrow-left-line"></i></button>;

    };

    return (
        <>
            <section className="sc-testimonial-area sc-pt-125 sc-pb-120 sc-md-pt-60 sc-md-pb-60">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center">
                                <h2 className="heading-title">Що про нас розказують організатори</h2>
                            </div>
                        </div>
                    </div>
                    <div className="home-testimonial-wrapper">
                        <Carousel className="home-testimonial"
                            infinite={true}
                            draggable={true}
                            pauseOnHover={true}
                            slidesToSlide={1}
                            swipeable={true}
                            autoPlaySpeed={3000}
                            customRightArrow={<CustomRightArrow />}
                            customLeftArrow={<CustomLeftArrow />}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 992
                                    },
                                    items: 1,
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 991,
                                        min: 577
                                    },
                                    items: 1,
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 576,
                                        min: 1
                                    },
                                    items: 1,
                                }
                            }}>
                            <div className="sc-testimonial-item">
                                <div className="quote-icon quote-icon_1">
                                    <i className="ri-double-quotes-l"></i>
                                </div>
                                <div className="description">
                                Мене звати Максим Черниш, я організатор фестивалю WellFest.
<br />
Я розумію, наскільки складно організовувати змагання і скільки часу потрібно на підготовку, реєстрацію, планування та проведення. Це великий напружений процес, який можна спростити за допомогою системи ARENA.
<br />
Це дійсно революційний продукт для організаторів. І я б так не говорив, якби сам не спробував цю систему і не зрозумів, що вона стане проривом для всіх організаторів змагань.


                                </div>
                                <div className="testimonial-image text-center">
                                    <img src={chernish} alt="Максим Черниш" />
                                </div>
                                <div className="test-person-wrap text-center">
                                    <h5 className="test-title sc-mb-0"><Link to="#">Максим Черниш
</Link></h5>
                                    <p className="designation ">Організатор</p>
                                </div>
                            </div>
                            <div className="sc-testimonial-item">
                                <div className="quote-icon quote-icon_1">
                                    <i className="ri-double-quotes-l"></i>
                                </div>
                                <div className="description">
                                Працюючи з платформою, я відкрив для себе новий рівень зручності та ефективності у проведенні спортивних змагань. Ця система надає широкий спектр інструментів, які полегшують організацію та ведення подій будь-якого масштабу.
                                </div>
                                <div className="testimonial-image text-center">
                                    <img src={testImage} alt="TestimonialImage" />
                                </div>
                                <div className="test-person-wrap text-center">
                                    <h5 className="test-title sc-mb-0"><Link to="#">Острозька Мар'яна
</Link></h5>
                                    <p className="designation ">Організатор</p>
                                </div>
                            </div>
                            <div className="sc-testimonial-item">
                                <div className="quote-icon quote-icon_1">
                                    <i className="ri-double-quotes-l"></i>
                                </div>
                                <div className="description">
                                Один із ключових плюсів цієї платформи - це зручний інтерфейс, який робить роботу з системою легкою та приємною. Я можу швидко створити подію, додати інформацію про учасників, встановити правила та розклад, все це всього кількома кліками. Інтуїтивний дизайн сприяє швидкому освоєнню платформи навіть для тих, хто вперше користується подібними інструментами.
                                </div>
                                <div className="testimonial-image text-center">
                                    <img src={groupImage2} alt="TestimonialImage" />
                                </div>
                                <div className="test-person-wrap text-center">
                                    <h5 className="test-title sc-mb-0"><Link to="#">Добрянська Юстина
</Link></h5>
                                    <p className="designation ">Суддя</p>
                                </div>
                            </div>
                            <div className="sc-testimonial-item">
                                <div className="quote-icon quote-icon_1">
                                    <i className="ri-double-quotes-l"></i>
                                </div>
                                <div className="description">
                                Загалом, ця платформа є надійним інструментом для організаторів спортивних подій. Вона об'єднує в собі простоту використання та широкий функціонал, роблячи управління та проведенням змагань максимально ефективними. Я впевнений, що arena.promo стане важливим помічником для кожного, хто займається організацією спортивних заходів.
                                </div>
                                <div className="testimonial-image text-center">
                                    <img src={groupImage5} alt="TestimonialImage" />
                                </div>
                                <div className="test-person-wrap text-center">
                                    <h5 className="test-title sc-mb-0"><Link to="#">Слободян Віталій
</Link></h5>
                                    <p className="designation ">Учасник змагань</p>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                    <div className="test_left_imgage">
                        <img src={groupImage} width="82" height="82" alt="groupImage" />
                    </div>
                    <div className="test_left_imgage1">
                        <img src={groupImage1} width="60" height="60" alt="groupImage1" />
                    </div>
                    <div className="test_left_imgage2">
                        <img src={groupImage2} width="82" height="82" alt="groupImage2" />
                    </div>
                    <div className="test_right_imgage">
                        <img src={groupImage3} width="82" height="82" alt="groupImage3" />
                    </div>
                    <div className="test_right_imgage1">
                        <img src={groupImage4} width="60" height="60" alt="groupImage4" />
                    </div>
                    <div className="test_right_imgage2">
                        <img src={groupImage5} width="82" height="82" alt="groupImage5" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Testimonial;
