import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../../assets/images/white-logo.png'
import paymentSystems from '../../assets/images/payment-systems.svg'

const Footer = () => {
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">
                        <Fade fade delay={100}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-25">
                                        <Link to="#"><img width="150" src={whiteLogo} alt="FooterLogo" /></Link>
                                    </div>
                                    <p className="footer-des">Ключ до успіху - це успішне управління спортивними змаганнями.</p>
                                    <ul className="about-icon">
                                        {/*<li>
                                            <a href="https://facebook.com/tournaments.shop" target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                        </li>*/}
                                        <li>
                                            <a href="https://t.me/ArenaPromoBot" target="_blank" rel="noreferrer"><i className="ri-telegram-fill"></i></a>
                                        </li>
                                        {/*<li>
                                            <a href="https://www.instagram.com/tournaments.shop" target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                        </li>*/}
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Компанія</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/about">Про нас</Link></li>
                                        <li><Link to="/events">Події</Link></li>
                                        <li><Link to="/price">Ціни</Link></li>
                                        <li><Link to="/contact">Контакти</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={300}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Види спорту</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/sports/air-gymnastics">Повітряна гімнастика</Link></li>
                                        <li><Link to="/sports/sport-dances">Бальні та спортивні танці</Link></li>
                                        <li><Link to="/sports/pole-dance">Pole dance</Link></li>
                                        <li><Link to="/sports/modern-dances">Сучасні танці</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={400}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Контакти</h4>
                                    <ul className="footer-menu-list">
                                        <li><i className="ri-mail-fill"></i><a href="mailto:hello@yourmail.com">arena.promo.ua@gmail.com</a></li>
                                        <li><i className="ri-phone-fill"></i><a href="tel:380938406236">+380 93 609 67 84</a></li>
                                        <li className="footer-map"><i className="ri-map-pin-fill"></i><span>Україна, м. Дніпро</span></li>
                                    </ul>
                                    <img src={paymentSystems} alt="Платіжні системи" />
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>&copy; {new Date().getFullYear()} <Link to="#"> Arena.promo, </Link> All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;
