import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/sports/SportDances-01.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Бальні та спортивні танці</span>
                                        <h2 className="heading-title sc-mb-25">Ритм життя в кожному кроці</h2>
                                        <div className="description sc-mb-25">
                                        Бальні танці — різновид парних танців, упорядкованих та кодифікованих для спортивних змагань. Спортивні танці визнаються Міжнародним олімпійським комітетом як кандидат в олімпійські види спорту. Термін бальні танці походить від слова бал, яке в свою чергу походить від латинського ballare, що означає танцювати.
                                        </div>
                                        <div className="description sc-mb-25">
                                            Спортивно-бальниі танці поділяються на дві групи: Стандартну(європейську) програму: Повільний вальс. Віденський вальс. Фокстрот. Квікстеп. Танго. Латиноамерикаську програму: Самба. Румба. Ча-ча-ча. Пасодобль. Джайв
                                        </div>


                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Повільний (англійський) вальс - бальний танець європейської програми. </li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Віденський вальс - бальний танець, швидкий різновид вальсу. Назва походить від "Віденського конгресу", на якому цей танець виконано вперше.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Фокстрот— найскладніший танець стандартної програми, бо вимагає ідеального контролю над тілом, над своїми рухами.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Квіксте́п(від англ. quick step швидкий крок) — бальний танець стандартної програми, швидкий фокстрот.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Танго Танго (ісп. Tango) - парний танець вільної композиції, що виконується під характерну музику та відрізняється енергійним і чітким ритмом. </li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Самба — один з бальних танців латино-американської програми, що виник під впливом бразильської самби і виконується під музику в стилі самба.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Румба(ісп. Rumba)парний  кубинський танець африканського походження.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Ча-ча-ча-  парний бальний танець кубинського походження.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Пасодо́бль(ісп. Paso Doble «подвійний крок») —іспанський танець, що імітує рухи, які відбуваються під час кориди. </li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Джайв(англ. jive) — бальний танець, розроблений в Англі під впливом американського свінгу. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
