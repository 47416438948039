import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';

function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

const Galleries = () => {
    const [ galleries, setGalleries ] = useState([])

    useEffect(() => {
        axios.get('https://api-festival-play.arena.promo/api/galleries').then(payload => {
            setGalleries(payload.data)
        })
    }, [])

    return (
        <>
            <div className="sc-team-pages-area white-color sc-pt-140 sc-pb-20 sc-md-pt-80">
                <div className="container">
                            {[...chunks(galleries, 4)].map(chunk => (
                              <div className="row">
                                  {chunk.map((gallery, index) => (
                                      <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                                          <div className="team-content-box text-center sc-mb-30">
                                              <div className="team-image">
                                                  <Link to={`/gallery/${gallery.tournament.base.id}`}>
                                                      <img src={gallery.cover.thumbnails['400']} alt={gallery.tournament.name} />
                                                  </Link>
                                              </div>
                                              <div className="team-author">
                                                  <h4 className="team-title sc-pt-15">
                                                      <Link to={`/gallery/${gallery.tournament.base.id}`}>{gallery.tournament.name}</Link>
                                                  </h4>
                                                  <span className="sc-mb-0">{gallery.tournament.date}</span>

                                              </div>
                                          </div>
                                      </div>
                                  ))}
                            </div>
                            ))}
                </div>
            </div>
        </>
    );
};


export default Galleries;
