import React from 'react';
import { Fade } from 'react-reveal';
import feature1 from '../../../../assets/images/features/Manager-01.png'
import feature2 from '../../../../assets/images/features/Manager-02.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature1} alt="Feature" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Система для організатора</span>
                                        <div className="description sc-mb-25">
                                          АРЕНА дозволяє ефективно керувати всіма етапами змагання від реєстрації до самого фіналу
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Реєстрація у телеграм боті</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Панель учасників з сортуванням і фільтрами</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Онлайн таймінг хвилина в хвилину</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Варіативність планування </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
