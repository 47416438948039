import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Form from './Form';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';
import axios from 'axios';

const Registration = (props) => {
    const params = new URLSearchParams(window.location.search)

    const [tournament, setTournament] = useState(null)

    const loadParticipant = async () => {
        const data = await axios.get(`https://api.arena.promo/api/tournament/info?jwt=`+params.get('jwt'))
        setTournament(data?.data)
    }

    useEffect(() => {
        loadParticipant()
    }, [0])

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            {!!tournament && (
                <>
                    <BreadCums pageTitle={`Реєстрація на ${tournament?.name}`} />
                    <Form tournament={tournament} />
                </>
            )}
            <Footer />
        </>
    );
};

export default Registration;
