import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import AirGymnasticsPricePlan from '../../Shared/AirGymnasticsPricePlan';
import PriceFaq from './PriceFaq';
import LatestBlog from '../../Shared/LatestBlog';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

const Price = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Ціни та тарифні плани' />
            <AirGymnasticsPricePlan />
            {/*<PriceFaq />*/}
            <LatestBlog />
            <Apps />
            <Footer />
        </>
    );
};

export default Price;
