import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import LatestBlog from '../../../Shared/LatestBlog';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Result = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Дякуємо за оплату!' />

            <div className="col-lg-12">
                <div className="description mt-3 text-center">
                    <p>
                        Деталі вашої оплати ви зможете дізнатись в своєму monobank застосунку.<br />
                        Квитанцію про успішну оплату буде автоматично додано до вашого Telegram аккаунту.
                    </p>
                </div>
            </div>

            <LatestBlog />
            <Apps />
            <Footer />
        </>
    );
};

export default Result;
