import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Ticket = props => {

    const url = `https://api-festival-play.arena.promo/api/tickets/${props.scope}/${props.id}/${props.timestamp}`

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <a className="sign-in-btn" href={url} target="_blank" style={{width: '200px', margin: 'auto', marginBottom: '20px'}}><span>Завантажити</span></a>
                        </div>
                        <div className="row">
                            <img src={url} style={{width: '500px', margin: 'auto', border: '1px solid lightblue'}} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Ticket;
