import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Sidebar from './Sidebar';
import axios from 'axios';

const Events = () => {
    const [ events, setEvents ] = useState([])

    useEffect(() => {
        axios.get('https://api-festival-play.arena.promo/api/tournaments').then(payload => {
            setEvents(
                payload.data.map(event => ({
                    ...event,
                    date: (new Date(Date.parse(event.date))),
                    tournament: event.air_gymnastics || event.sport_dances || event.festival,
                    tournament_key: event.air_gymnastics ? 'air-gymnastics' : (event.sport_dances ? 'sport-dances' : (event.festival ? 'festival' : ''))
                }))
            )
        })
    }, [])

    return (
        <>
            <div className="sc-blog-pages-area sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            {events.map((event, index) => (
                                <div className="blog-item sc-mb-30" key={index}>
                                    <div className="blog-image">
                                        <Link to={`/events/${event.id}`}>
                                            <img
                                                src={(event.tournament.media || [])[0]?.s3}
                                                alt={event?.name}
                                            />
                                        </Link>
                                    </div>
                                    <div className="blog-content text-left">
                                        <div className="blog_date">
                                            <ul>
                                                <li>{event.date.getDate()}</li>
                                                <li>
                                                    {event.date.toLocaleString('default', { month: 'long' })}
                                                </li>
                                            </ul>
                                        </div>
                                        {/*<div className="blog-list-menu list_menu">
                                            <ul className="blog-list">
                                                <li><i className="ri-edit-fill"></i><Link to="#"> Admin</Link></li>
                                                <li><i className="ri-chat-3-fill"></i><Link to="#"> 05 Comment</Link></li>
                                            </ul>
                                        </div>*/}
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold text-center">
                                            <Link to={`/events/${event.id}`}>{event?.name}</Link>
                                        </h3>
                                        <div className="description sc-mb-20">
                                            {(event.tournament?.description || '').split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </div>
                                        <Link to={`/events/${event.id}`} className="readmore">Детальніше</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-4">
                            <Sidebar></Sidebar>
                        </div>
                    </div>
                    {/*
                    <div className="spria-navigation text-center sc-md-pt-70">
                        <ul>
                            <li><Link to="#" className="active">1</Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#">3</Link></li>
                            <li><Link to="#">4</Link></li>
                            <li><Link to="#">5</Link></li>
                            <li><Link to="#" className="arrow arrow-line"><i className="ri-arrow-right-line"></i></Link>
                            </li>
                        </ul>
                    </div>
                    */}
                </div>
            </div>
        </>
    );
};

export default Events;
