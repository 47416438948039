import React from 'react';
import { Fade } from 'react-reveal';
import feature1 from '../../../../assets/images/features/Judge-01.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature1} alt="Суддівська система" style={{borderRadius: '15px'}}  />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Суддівська система</span>
                                        <h2 className="heading-title sc-mb-25">Справедливість за кліком!</h2>
                                        <div className="description sc-mb-25">
                                          Це комплекс програмного забезпечення та технологій, призначений для автоматизації та підтримки роботи суддів.
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Електронні документи</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Автоматизоване ведення оцінювання</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Аналітика та звітність</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Безпека даних</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
