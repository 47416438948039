import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import SchedeleFeature1 from './SchedeleFeature1';
import SchedeleFeature2 from './SchedeleFeature2';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Manager = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Онлайн табло' />
            <SchedeleFeature1 />
            <SchedeleFeature2 />
            <Apps />
            <Footer />
        </>
    );
};

export default Manager;
