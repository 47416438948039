import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Form from './Form';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

const Insurance = () => {
    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Страхувальний поліс' />
            <Form />
            <Footer />
        </>
    );
};

export default Insurance;
