import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Events from './Events';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

const EventsIndex = () => {

    return (
        <>
            <Header
                parentMenu='events'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Події' />
            <Events />
            <Apps />
            <Footer />
        </>
    );
};

export default EventsIndex;
