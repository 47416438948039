import React from 'react';
import SocialIcon from '../../Shared/SocialIcon';
import { HashLink as Link } from 'react-router-hash-link';
import teamImg1 from '../../../assets/images/manager/air.jpeg'
import teamImg2 from '../../../assets/images/manager/dance.webp'
import teamImg3 from '../../../assets/images/manager/modern.jpeg'
import teamImg4 from '../../../assets/images/manager/pole.jpeg'

const TeamMember = () => {
    return (
        <>
            <section>
                <div className="sc-team-pages-area white-color sc-pt-140 sc-pb-20 sc-md-pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <a href="https://manager.air.arena.promo" target="_blank">
                                  <div className="team-content-box text-center sc-mb-30">
                                      <div className="team-image">
                                          <img src={teamImg1} alt="Team" />
                                      </div>
                                      <div className="team-author">
                                          <h4 className="team-title sc-pt-15">Повітряна гімнастика</h4>
                                          <span className="sc-mb-0">Вхід</span>
                                      </div>
                                  </div>
                                </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <a href="https://manager.dance.arena.promo" target="_blank">
                                <div className="team-content-box text-center sc-mb-30">
                                    <div className="team-image">
                                        <img src={teamImg2} alt="Team" />
                                    </div>
                                    <div className="team-author">
                                        <h4 className="team-title sc-pt-15">Спортивні танці</h4>
                                        <span className="sc-mb-0">Вхід</span>
                                    </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <a href="https://manager.modern.arena.promo" target="_blank">
                                <div className="team-content-box text-center sc-mb-30">
                                    <div className="team-image">
                                        <img src={teamImg3} alt="Team" />
                                    </div>
                                    <div className="team-author">
                                        <h4 className="team-title sc-pt-15">Сучасні танці</h4>
                                        <span className="sc-mb-0">Вхід</span>
                                    </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <a href="https://manager.pole-dance.arena.promo" target="_blank">
                                <div className="team-content-box text-center sc-mb-30">
                                    <div className="team-image">
                                        <img src={teamImg4} alt="Team"  />
                                    </div>
                                    <div className="team-author">
                                        <h4 className="team-title sc-pt-15"><Link to="/team-details#">Pole dance</Link></h4>
                                        <span className="sc-mb-0">Вхід</span>
                                    </div>
                                </div>
                              </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default TeamMember;
