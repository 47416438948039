import React from 'react';
import Header from '../../../Shared/Header';
import BreadCums from '../../../Shared/BreadCums';
import ManagerFeature1 from './ManagerFeature1';
import ManagerFeature2 from './ManagerFeature2';
import ManagerFeature3 from './ManagerFeature3';
import ManagerFeature4 from './ManagerFeature4';
import ManagerFeature5 from './ManagerFeature5';
import Apps from '../../../Shared/Apps';
import Footer from '../../../Shared/Footer';

const Manager = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Система для організатора' />
            <ManagerFeature1 />
            <ManagerFeature2 />
            <ManagerFeature3 />
            <ManagerFeature4 />
            <ManagerFeature5 />
            <Apps />
            <Footer />
        </>
    );
};

export default Manager;
