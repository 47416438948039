import React from 'react';
import aboutImg1 from '../../../assets/images/about/ab_img.png'
import aboutImg2 from '../../../assets/images/about/ab_img1.png'
import aboutImg3 from '../../../assets/images/about/ab_img2.png'

const AboutTeam = () => {
    return (
        <>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 p-z-idex">
                                <div className="sc-heading-area sc-mb-105 sc-md-mb-60 ">
                                    {/*<span className="sc-sub-title primary-color text-center">Про Arena</span>*/}
                                    <h2 className="heading-title sc-mb-25 text-center primary-color">Ласкаво просимо до Arena!</h2>
                                    <div className="description sc-mb-20 text-left">
                                    <p>Arena.promo - це система управління спортивними змаганнями. Вона включає в себе такі компоненти:</p>
<ul>
<li><b>Система для організатора:</b> Ця частина системи дозволяє організаторам змагань ефективно керувати всіма аспектами події, включаючи реєстрацію учасників, планування розкладу, управління командами та багато іншого.</li>

<li><b>Суддівська система:</b> Завдяки цій системі судді можуть легко оцінювати виступи учасників, виставляти бали, вести протоколи та проводити інші дії, пов'язані з оцінюванням.</li>

<li><b>Телеграм-бот для реєстрації:</b> Даний бот дозволяє учасникам зареєструватися на змагання швидко та зручно через популярний месенджер Telegram. Він надає можливість заповнити необхідну інформацію та отримати підтвердження реєстрації.</li>

<li><b>Онлайн таймінг та табло:</b> Ця функція дозволяє вести облік часу під час змагань та відображати різноманітну інформацію на електронних табло. Вона допомагає учасникам та глядачам бути в курсі останніх змін та результатів.</li>
</ul>

<p>Arena.promo - це інноваційна система, яка спрощує організацію та проведення спортивних змагань, забезпечуючи зручність та ефективність у керуванні всіма аспектами події.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row position-relative">
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center sc-md-mb-30">
                                    <img src={aboutImg1} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center sc-md-mb-30">
                                    <img src={aboutImg2} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center">
                                    <img src={aboutImg3} alt="About" />
                                </div>
                            </div>
                            <div className="sc-ab-circle purple"></div>
                            <div className="sc-ab-circle yelow"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutTeam;
