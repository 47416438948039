import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useParams } from 'react-router';

const Form = ({ participant }) => {
    const props = useParams();
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ progress, setProgress ] = useState(false);

    const fileRef = React.createRef()

    const upload = async (video) => {
        setProgress(true)

        const formData = new FormData();
        formData.append('scope', props.scope);
        formData.append('type', props.type);
        formData.append('id', props.id);
        formData.append('timestamp', props.timestamp);
        formData.append('video', video);

        const data = await axios.post(`https://api.arena.promo/api/video/${props.scope}/${props.type}/${props.id}/${props.timestamp}`, formData, {headers: {
            'content-type': 'multipart/form-data',
        }})

        setProgress(false)

        if(data?.data?.id){
            setSuccess(true);
        }else{

        }
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <h4 className="heading-title sc-mb-25" style={{textAlign: 'center'}}>
                                Завантаження відео для учасника {participant?.participant?.name} до турніру {participant?.tournament?.name}
                            </h4>
                        </div>
                        <div className="row">
                            <input id='selectVideo' type="file" onChange={(e) => upload(e.target.files[0])} ref={fileRef} style={{display: 'none'}} />

                            {!success && !progress && (
                                <span className="sign-in-btn" style={{width: '200px', margin: 'auto', marginBottom: '20px'}}
                                    onClick={() => fileRef.current.click()}
                                >
                                    <span>Завантажити</span>
                                </span>
                            )}

                            {!!progress && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'blue'}}>
                                    Відео завантажується...
                                </h4>
                            )}

                            {!!success && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'green'}}>
                                    Відео успішно завантажено
                                </h4>
                            )}

                            {!!error && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'red'}}>
                                    Помилка завантаження. Спробуйте ще раз.
                                </h4>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form;
