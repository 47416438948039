import React from 'react';
import { Fade } from 'react-reveal';
import feature1 from '../../../../assets/images/features/Reports-01.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={feature1} alt="Feature" style={{borderRadius: '15px'}} />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Звітність</span>
                                        <h2 className="heading-title sc-mb-25">Контрольуйте ваш успіх: система звітностей - ваш надійний партнер!</h2>
                                        <div className="description sc-mb-25">
                                          Наша система звітностей про оцінки учасникам конкурсів - це потужний інструмент, який допомагає організаторам ефективно відстежувати та аналізувати рейтинги і результати учасників. Завдяки простому та інтуїтивно зрозумілому інтерфейсу, ви з легкістю можете переглядати детальні звіти про оцінки кожного учасника, порівнювати їхні досягнення та розвивати стратегії подальшого розвитку події. Наша система забезпечує вам повний контроль над процесом оцінки та надає вам цінні інсайти для прийняття обґрунтованих рішень. Довіртеся нашій системі звітностей - ваш успіх у ваших руках!"
                                        </div>
                                        <div className="description sc-mb-25">
                                          Переваги:
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Система забезпечує об'єктивну оцінку учасників на основі заздалегідь визначених критеріїв, що дозволяє уникнути суб'єктивності та неоднозначності.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Всі оцінки та дані про учасників зберігаються в системі, що дозволяє легко відстежувати прогрес кожного учасника на протязі часу та використовувати ці дані для подальшого аналізу.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Система звітностей допомагає ефективно керувати процесом оцінки учасників, відстежувати виконання завдань та вчасно реагувати на потреби конкурсу.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Інтуїтивно зрозумілий інтерфейс дозволяє легко навігувати по системі, швидко виконувати необхідні завдання та отримувати необхідну інформацію.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Автоматизований процес оцінки учасників дозволяє зменшити витрати часу та зусиль на адміністративні завдання, що дозволяє сконцентрувати увагу на якісній організації конкурсу.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>


                </div>
            </section>
        </>
    );
};

export default ServiceFeature;
